@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body, html {
  height: 100%;
}

body {
  margin: 0;
  font-family: Urbanist, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
  background: linear-gradient(to bottom, #00095a 0%, #000000 100%);
  background-attachment: fixed;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.homeblock {
  max-width: 1110px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  /*padding: 15px;*/
  margin: 20px auto 20px auto;
  /*background-color: rgb(243 244 246);*/
  padding-top: 6px;
  padding-bottom: 30px;
}

.vvblock {
  background: #ffffff;
  color: #000000;
  margin-bottom: 24px;
  padding: 30px;
  border-radius: 25px;
  overflow: hidden;
}


.vvblocktitle {
  height: 67px;
  line-height: 67px;
  background: #F6F6F6;
  color: #010101;
  font-size: 24px;
  font-weight: 900;
  text-align: center;
  margin-top: -30px; /* Pulls the element up by the padding amount */
  margin-left: -30px; /* Pulls the element left by the padding amount */
  margin-right: -30px; /* Pulls the element right by the padding amount */
  width: calc(100% + 60px); /* Increases width to compensate for negative margins */
  margin-bottom: 36px;
}

.vvinnerblock {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 24px;
}

.vvinnerblock-bigger {
  font-size: 18px;
  line-height: 22px;
}

.vvinnerblock:last-child {
  margin-bottom: 0;
}

.eventprofilepic {
    width: 100%;
    border-radius: 25px;
    position: relative;
    margin-bottom: 24px;
    overflow: hidden;
    max-height: 400px;
}

.eventprofilepic img {
  width: 100%; /* Ensure it doesn't overflow its container */
  height: 100%;   /* Maintain aspect ratio */
}

.uploaddisc {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #000000;
  bottom: 24px;
  right: 24px;
  padding: 13px;
  cursor: pointer;
}

.uploaddisc img {
  width: 24px;
  height: 24px;
}

.bigtrophytitle {
  font-family: Bungee;
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  margin-bottom: 24px;
}

.bigtropydesc {
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  margin-bottom: 24px;
}

#navi {

}


#trophies {

}

#userpic {
  width: 97px;
  height: 97px;
  border-radius: 50px;
  float: left;
  background-image: url('../public/images/Person.png');
  background-color: #D5D5D5;
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
}

#userpic img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the area */
}

#userinfo {


}

.jointeamheaderblock {
    height: 132px;
    margin-bottom: 0px;
    margin-top: 0px;
    padding-bottom: 0px;
}

.signinheaderblock {
    height: 132px;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.signinheader {
  font-size: 32px;
  font-weight: 900;
  line-height: 102px;
}

.signinblock {
  border-radius: 25px;
  background-color: #ffffff;
  padding: 24px;
}

.inputFieldSignIn {
  width: 100%;
  padding: 0px;
  height: 42px;
  line-height: 42px;
  margin: 0px 0px 32px 0px;
  border: 0px;
  border-bottom: 1px solid #C1C1C1;

  background-color: transparent;
  font-family: Urbanist;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: #333333;
   outline-width: 0;
   outline: none;

}

.inputFieldGeneral {
  width: 100%;
  padding: 0px;
  height: 42px;
  line-height: 42px;
  margin: 0px 0px 32px 0px;
  border: 0px;
  border-bottom: 1px solid #C1C1C1;

  background-color: transparent;
  font-family: Urbanist;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: #333333;
   outline-width: 0;
   outline: none;

}

.sociallogintitle {
  font-size: 20px;
  font-weight: 500;
  line-height: 50px;
  text-align: left;
  float: left;
}

.socialloginblock {
  padding-left: 8px;
  padding-right: 8px;
}

.sociallogins, .socialshares {
  float: right;
}

.signinball, .shareball {
  float: left;
  margin-left: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: #ffffff;
  text-align: center;
}

.signinball img, .shareball img {
  width: 24px;
  height: 24px;
  margin: 13px;
}

.signin-fb {
  display: flex;
  width: 300px;
  padding: 15px;
  align-items: flex-start;
  gap: 15px;
  border-radius: 10px;
  background: #1877F2;
  color: #FFF;
  font-family: Helvetica;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-left: auto;
  margin-right: auto;
}

.signin-google {
  display: flex;
  width: 300px;
  padding: 15px;
  align-items: flex-start;
  gap: 15px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.17), 0px 0px 3px 0px rgba(0, 0, 0, 0.08);
  color: rgba(0, 0, 0, 0.54);
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-left: auto;
  margin-right: auto;
}

.signin-apple {
  display: flex;
  width: 300px;
  padding: 15px;
  align-items: flex-start;
  gap: 15px;
  border-radius: 10px;
  background: #000;
  color: #FFF;
  font-family: SF Pro Display;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-left: auto;
  margin-right: auto;
}

.text-xl {
  font-size: 1.5rem;
}

#userinfowrapper {
  margin-left: 18px;
  margin-top: 16px;
  float: left;
  position: relative;
  width: calc(100% - 115px);
}

#usernameui {
  font-size: 24px;
  font-weight: 900;
  line-height: 29px;
  color: #ffffff;
  margin-bottom: 6px;
}

#userlocation {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: #ffffff;
}

.trophies {
  background-color: #ffffff;
  border-radius: 25px;
  padding: 0px 0px 0px 0px;
  margin-top: 0px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  min-height: 80vh;
}

.trophytitle {
  height: 67px;
  line-height: 67px;
  background: #F6F6F6;
  color: #010101;
  font-size: 24px;
  font-weight: 900;
  text-align: center;
  width: 100%;
  margin-bottom: 33px;
}

.main {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.main-user {
  color: #ffffff;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
}

#user {
  /*background-color: #c0d6ed;*/
  position: relative;
}

.headertop {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 12px;
  padding-bottom: 36px;
}

.trophiescontainer {
    width: 100%;
}

.trophy {
  width: 150px;
  height: 150px;
  line-height: 150px;
  margin-left: 6px;
  margin-right: 6px;
  margin-bottom: 6px;
  display: inline-block;
  position: relative;
  border: 0px;
  text-decoration: none;
  overflow: hidden;
  border-radius: 20px;
  /*background: linear-gradient(to right, rgb(153, 27, 27), rgb(202, 138, 4), rgb(234, 179, 8));*/
}

.trophystyletrophy {
  width: 150px;
  height: 150px;
  line-height: 150px;
  margin-left: 6px;
  margin-right: 6px;
  display: inline-block;
  position: relative;
  border: 0px;
  text-decoration: none;
  overflow: hidden;
  border-radius: 20px;
}

.trophycontainer {
  display: inline-block;
  margin-bottom: 16px;
}

.notrophies {
  color: #101010;
  padding-bottom: 30px;
}

.trophyblock {
  max-width: 1110px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  /*padding: 15px;*/
  margin: 20px auto 20px auto;
  /*background-color: rgb(243 244 246);*/
  padding-top: 30px;
  padding-bottom: 10px;

}

#bigtrophy {
  background-color: #c0d6ed;
  padding-top: calc(9 / 16 * 100%);
  position: relative;
}

#bigtrophyarrow {
  position: absolute;
  top: 12px;
  left: 12px;
  font-size: 3rem;
  line-height: 2.3rem;
}

.trophyname {
  position: absolute;
  bottom: -24px;
  font-size: 15px;
}

#trophytitle {
  margin-left: 15px;
  margin-bottom: 15px;
}

#bigtrophytitle {
  font-size: 2rem;
  margin-bottom: 16px;
}

#bigtrophyname {
  color: white;
  font-size: 5em;
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  line-height: 0px;
}

.bigtrophytext {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 24px;
}

.transfertrophy {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 30px;
}

.transfertrophy img {
    float: left;
    display: inline-block;
    width: 30px;
    margin-right: 20px;
    margin-top: 6px;
}


.newtrophymodal {
  color: #000000;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
}

.newtrophymodaltitle, .generalmodaltitle {
  font-family: Bungee;
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  margin-top: 16px;
  margin-bottom: 6px;
}

.newtrophymodalimagewrapper {
  position: relative;
  width:225px;
  height: 225px;
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
}

.newtrophymodalimage {

  border-radius: 20px;
  width: 225px;
  height: 225px;
  /*background: linear-gradient(to right bottom, rgb(236, 72, 153), rgb(239, 68, 68), rgb(234, 179, 8));*/
  position: absolute;
  transform-origin: bottom center;
}

.newtrophymodalstylename {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
}

.trophystylename {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #010101;
}

.trophy1 {
  z-index: 1005;
}

.trophy2 {
  z-index: 1004;
  background: linear-gradient(to right bottom, rgb(134, 239, 172), rgb(59, 130, 246), rgb(147, 51, 234));
  transform:rotate(5deg);
}

.trophy3 {
  z-index: 1003;
  background: linear-gradient(to left top, rgb(254, 240, 138), rgb(250, 204, 21), rgb(161, 98, 7));
  transform:rotate(10deg);
}

.trophy4 {
  z-index: 1002;
  background: linear-gradient(to right bottom, rgb(29, 78, 216), rgb(30, 64, 175), rgb(17, 24, 39));
  transform:rotate(15deg);
}

.trophy5 {
  z-index: 1001;
  background: linear-gradient(to left top, rgb(34, 197, 94), rgb(21, 128, 61));
  transform:rotate(20deg);
}


.newtrophymodalbutton {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: #000000!important;
  text-align: center;
  margin-bottom: 16px;
  cursor: pointer;
}

#modalname {
  color: white;
  font-size: 5em;
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  line-height: 0px;
  z-index: 4;
}

.bigtrophycount {
  opacity: 0.4;
  margin-top: 12px;
}

.shareItem {
  float: left;
  margin-right: 15px;
  line-height: 50px;
}

.trophybigimagecontent {
  /*position: absolute;
  top: 0;*/
}

.trophybigimagecontent img {
  width: 100%;
}

.trophybigimage {
  width: 100%;
  max-height: 400px;
  /*padding-top: 100%;*/
  position: relative;
  background: #d0d0d0;
  border-radius: 25px;
  overflow: hidden;
}

.bigtrophynumber {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}

.bigtrophyshare {
  font-size: 20px;
  font-weight: 500;
  line-height: 50px;
}

.shareIcon {
  width: 50px;
  height: 50px;
  border-radius: 25px;
}

#modalwrapper {position: absolute;width:100%;height:100%}

#modal {
  position: fixed;
  width: calc(100% - 40px);
  padding-top: calc(9 / 16 * 100%);
  margin-top: 30px;
  z-index: 3;
  margin-left: 20px;
  margin-right: 20px;
  display: none;

}

.respTextVenly {
  position: absolute;
  left: 0px;
  top: 60px;
}

.venlybuttons {
  position: fixed;
}

.respTextThirdweb {
  position: absolute;
  right: 0px;
  top: 60px;
}

/* GENERATED ADMIN CSS */


/* ADMIN HOME */



/* Admin user styles */
#adminuser {
  display: flex;
  align-items: center;
}

#adminuserpic {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 15px;
}

#adminusername {
  font-size: 20px;
  margin-bottom: 5px;
}

/* Admin control buttons */


#admincontrols {
  margin-top: 24px;
}

.adminbtn {
  background: linear-gradient(to right, #000642 0%, #00359C 100%);
  eight: 78px;
  line-height: 78px;
  border-radius: 25px;
  text-align: center;
  width: 100%;
  margin-bottom: 24px;
  color: #52FF00;
  font-family: Urbanist;
  font-size: 20px;
  font-weight: 900;
  display: block;
}

.disabled {
  color: #dddddd;
}

.truncate {
  white-space: nowrap; /* Keep the text in a single line */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis (...) at the end */
}


.adminbtn:last-child {
  margin-bottom: 0px;
}

.signin-btn {
  background: linear-gradient(to right, #000642 0%, #00359C 100%);

  width: 100%;
  height: 78px;
  line-height: 78px;
  border-radius: 25px;
  text-align: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
  color: #52FF00;
  font-family: Urbanist;
  font-size: 20px;
  font-weight: 900;

}

.distribute-btn {
  background: linear-gradient(to right, #000642 0%, #00359C 100%);
  width: 100%;
  height: 78px;
  line-height: 78px;
  border-radius: 25px;
  text-align: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  color: #52FF00;
  font-family: Urbanist;
  font-size: 20px;
  font-weight: 900;

}

.big-btn {
  background: linear-gradient(to right, #000642 0%, #00359C 100%);
  width: 100%;
  height: 78px;
  line-height: 78px;
  border-radius: 25px;
  text-align: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  color: #52FF00;
  font-family: Urbanist;
  font-size: 20px;
  font-weight: 900;

}

.small-btn {
  width: 133px;
  height: 35px;
  border-radius: 45px;
  background: #010529;
  font-family: Urbanist;
  font-size: 16px;
  font-weight: 900;
  line-height: 35px;
  color: #52FF00;
  display: block;
  text-align: center;

}

.error-message {
    color: #dd0000;
}

.message {
  text-align: center;
}

.newtrophymodalstylebutton {
  background: linear-gradient(to right, #000642 0%, #00359C 100%);
  width: 100%;
  height: 78px;
  line-height: 78px;
  border-radius: 25px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
  color: #52FF00;
  font-family: Urbanist;
  font-size: 20px;
  font-weight: 900;
  width: calc(50% - 4px);
  float: left;
}

.newtrophymodalstylebuttonleft {
  margin-right: 8px;
}

.jointeam-btn {
  background: linear-gradient(to right, #000642 0%, #00359C 100%);

  width: 100%;
  height: 78px;
  line-height: 78px;
  border-radius: 25px;
  text-align: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  color: #52FF00;
  font-family: Urbanist;
  font-size: 20px;
  font-weight: 900;
}

.btn {
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  text-align: center;
  width: 100%;
  transition: background-color 0.3s ease;
}

.btn-small {
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  text-align: center;
  width: 70%;
  transition: background-color 0.3s ease;
}

.homelink {
  top: 8px;
}

.btn-round {
  padding: 10px 15px;
  border-radius: 24px;
  color: white;
  text-align: center;
  transition: background-color 0.3s ease;
}

.eventDate {
  float: left;
  width: 195px;
}

.event, .team, .teamuser {
  display: block;
}

.adminbtn:hover {
  background-color: #007acc; /* Adjust color for hover effect if needed */
}

/* Teams and competitions lists */
.team, .competition {
  margin-bottom: 5px;
}

#teamtitle, #ongoingtitle, #comingtitle, #pasttitle, #divisiontierstitle, #generaltitle {
  margin-bottom: 10px;
}

.divisionName {
  font-weight: 900;
}

/* CREATE TEAM */

/* Team creation styles */
.inputField {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.inputFieldJoinTeam {
  padding: 0px;
  height: 42px;
  line-height: 42px;
  margin: 0px 0px 32px 0px;
  border: 0px;
  border-bottom: 1px solid #C1C1C1;
  background-color: transparent;
  font-family: Urbanist;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: #333333;
 outline-width: 0;
 outline: none;
}

.inputFieldFirstHalf {
  width: 48%;
  margin-right: 2%;
}

.inputFieldSecondHalf {
  width: 48%;
}

.inputFieldTier {
  width: 35px;
  padding: 5px 0px 5px 5px;
  margin: 10px 0;
  border: 0px;
  background: #F6F6F6;
  height: 28px;
  border-radius: 5px;
  margin-left: 6px;
  margin-right: 0px;
  line-height: 18px;

}


input[type='date']:before {
  color: #aaa;
  content: attr(placeholder);
  width: 40%;
}



.tiershalf-1 {
  width: 65%;
  overflow: hidden;

}

.tiershalf-2 {
  width: 65%;
  overflow: hidden;
  margin-right: -30%;
}



.adddivision {
  font-weight: 900;
  cursor: pointer;
}

.adddivision img {
  margin-right: 8px;

  display: block;
  float: left;
}
.division {
  margin-bottom: 20px;
}

.division label {
  float: left;
  display: block;
}

.inputFieldTier {
  float: left;
  display: block;
  margin-top: -2px;
}

.tierball {
  background: #010529;
  height: 24px;
  width: 24px;
  float: left;
  border-radius: 12px;
  margin-right: 6px;
}

.green-disc {
  background: url('../public/images/tierball.png');
}

.selectFieldDistributeTrophy {
  width: 140px;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: inline-block;
  float: right;
}

.user-item {
  margin-bottom: 4px;
}

/* Team list styles */
#adminteams .team {
  margin-bottom: 5px;
}

/* Overlay styles */
.dialog-overlay {
  position: fixed;       /* Fixed position */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Black with opacity */
  display: flex;         /* Flexbox to center the dialog */
  align-items: center;   /* Vertical center alignment */
  justify-content: center; /* Horizontal center alignment */
  z-index: 3000;          /* To make sure it stays on top */
}

/* Modal dialog styles */


.close-button {
  position: absolute;    /* To position relative to the modal */
  top: 8px;             /* Distance from the top */
  right: 18px;           /* Distance from the right */
  background: none;      /* No background */
  border: none;          /* No border */
  font-size: 24px;       /* Set size */
  cursor: pointer;       /* Change mouse cursor on hover */
  outline: none;         /* Remove focus border */
}

.qr {
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
}


/* USER JOIN TEAM MODAL */

/* The Modal (background) */
.modal {
  position: fixed; /* Stay in place */
  z-index: 3000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  display: flex;
  align-items: center; /* Align modal content vertically center */
  justify-content: center; /* Align modal content horizontally center */
}

/* Modal Content */
.modal-content, .dialog {
  /*
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  animation-name: animatetop;
  animation-duration: 0.4s;
  position: relative;
  color: #010101;
  */
  background-color: #fff;
  padding: 20px;
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 400px;
  position: relative;
  color: #010101;
  text-align: center;
}



/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  line-height: 16px;
  right: 18px;
  top: 18px;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Add Animation */
@keyframes animatetop {
  from {top: -300px; opacity: 0}
  to {top: 0; opacity: 1}
}

/* distribute trophies success dialog */
.success-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other elements */
}

.dialog-content {
  background-color: #fff;
  padding: 32px;
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 400px;
  width: 80%;
  color: #000000;
  font-size: 18px;
  line-height: 21.6px;
}

.dialog-content h2 {
  margin-top: 0;
}



/* USER TROPHIES */

.user-trophies-container {
  padding: 20px;
}

.user-info {
  /*display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;*/
}

.user-info .avatar {
  width: 50px;
  height: 50px;
  background-color: #blue; /* Replace with avatar image if available */
  border-radius: 50%;
}

.user-info .name {
  font-size: 20px;
  font-weight: bold;
}

.user-info .location {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: #ffffff;

}
/*
.trophies-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
}


.trophy {
  display: flex;
  flex-direction: column;
  align-items: center;
}
*/

.trophy-image {
  width: 100%;
  height: 150px; /* Adjust based on actual image aspect ratio */
  background-size: cover;
  background-position: center;
  margin-bottom: 10px;
}

.trophy-name {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  line-height: 150px;
}

.logout {
  position: absolute;
  top: 0px;
  right: 8px;
  text-decoration: underline;
  z-index: 999;
}


/* universal modal */

.modal-uni-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-uni {
  background-color: #fff;
  padding: 20px;
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 400px;
  position: relative;
}

.modal-uni-close {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
}

/* pulsating in size */

@keyframes pulse {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.trophy-name-unseen {
  animation: pulse 2s infinite;

}

.trophyNameOnTrophy {
  bottom: 7.4vw;
  color: #000;
  font-weight: 700;
  position: absolute;
  text-align: center;
  width: 100%;
  font-size: 4vw;
  margin-left: 0.5vw;
}

.trophy-details {
  position: relative;
  margin-bottom: 24px;
}

.someicon {
  background-color: black;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-left: 4px;
}

.team_profile_pic {
  width: 180px;
  height: 200px;
  margin-left: auto;
  margin-right: auto;
}

.settings {
  width: 86px;
  height: 60px;
  position: fixed;
  right: 0px;
  bottom: 18px;
  background: #010A5A;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  z-index: 2000;
}

.logout {
  width: 86px;
  height: 60px;
  position: fixed;
  right: 0px;
  top: 18px;
  background: #010A5A;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  z-index: 2000;
}

.logout img {
  width: 33px;
  height: 24px;
  margin-top: 17px;
  margin-left: 26px;
}

.adminhomelink {
  width: 86px;
  height: 60px;
  position: fixed;
  right: 0px;
  bottom: 18px;
  background: #010A5A;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  z-index: 2000;
}

.adminhomelink img {
  transform: rotate(90deg);
  width: 15px;
  margin-top: 18px;
  margin-left: 35px;}

.back {
    width: 62px;
    height: 60px;
    position: fixed;
    left: 0px;
    bottom: 18px;
    background: #010A5A;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    z-index: 2000;
    cursor: pointer;
  }

.back_home {
  width: 106px;
  height: 60px;
  position: fixed;
  left: 0px;
  bottom: 18px;
  background: #010A5A;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  z-index: 2000;
  cursor: pointer;
}

.settings img {
  width: 45px;
  height: 45px;
  margin-top: 8px;
  margin-left: 22px;
}

img.backimg, .back img {
  width: 14px;
  margin-top: 19px;
  margin-left: 19px;
  display:block;
  float: left;
}

img.homeimg {
  width: 25px;
  height: 23px;
  display:block;
  float: left;
  margin-left: 22px;
  margin-top: 17px;
}

.termsul {
  list-style: initial;
  margin: initial;
  padding: 0 25px;
}


/* toggleSwitch */
.toggledot {
  height: 35px;
  width: 35px;
  background-color: #52FF00;
  transition: transform 0.3s;
}

input:checked ~ .toggledot {
  transform: translateX(35px);
}

/* Customizing the color and the shape to match the toggle in the image */
input:checked ~ .toggleblock {
  background-color: #010529; /* TailwindCSS green-400 */
}

.toggleblock {
  width: 70px;
  height: 35px;
  transition: background-color 0.3s;
}

.profilesharetext {
  width: calc(100% - 90px);
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
}

.profilesharetoggle {
  margin-top: 4px;
}

.profileshareicons img {
  margin-left: 15px;
  margin-right: 15px;
  float: left;
}

.profileshareicons img:first-child {
  margin-left: 0;
}

.profileshareicons img:last-child {
  margin-right: 0;
}

.profileaddprofilepic {
  border-radius: 25px;
  height: 78px;
  background-color: #E8E8E8;
  cursor: pointer;
}

.profileaddprofilepic_pic {
  width: 78px;
  height: 78px;
  border-radius: 25px;
  background-image: url('../public/images/Person.png');
  background-color: #D5D5D5;
  background-repeat: no-repeat;
  background-position: center center;
  float: left;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #D5D5D5;
}

.profileaddprofilepic_pic img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the area */
}

.profileaddprofilepic_text {
  float: left;
  text-align: center;
  font-family: Urbanist;
  font-size: 18px;
  font-weight: 700;
  line-height: 78px;
  color: #010101;
  width: calc(100% - 80px);
}

.profilemyteam {
  width: 100%;
  margin-bottom: 30px;
}

.profilemyteam:last-child {
  margin-bottom: 0px;
}

.profilemyteamname {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #010101;
  float: left;
}

.profileleaveteam {
  float: right;
  margin-top: -7px;
}


.custom-select-wrapper {
  position: relative;
  display: inline-block;
  user-select: none;
  float: right;
}

.custom-select-wrapper select {
  background-color: #010529; /* Replace with the actual color code from the image */
  color: #52FF00;
  padding: 5px 35px 5px 20px; /* Adjust these values to match the size in the image */
  font-size: 16px; /* Adjust as necessary */
  border: none;
  border-radius: 20px; /* Adjust if the corners are more or less rounded */
  -webkit-appearance: none; /* Removes default styling for WebKit browsers */
  -moz-appearance: none; /* Removes default styling for Mozilla browsers */
  appearance: none;
  font-weight: 700;
}

.custom-select-wrapper::after {
  /*content: '▼';
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  color: white;
  pointer-events: none;*/
  content: '';
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #52FF00; /* Adjust the color to match the text color */
  pointer-events: none;

}

/* Add styles for hover and focus states as needed */
.custom-select-wrapper select:hover,
.custom-select-wrapper select:focus {
  outline: none;
}
